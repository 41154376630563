<template>
  <div>
    <bread-crumb :name="'Search reults for '+ query"></bread-crumb>
    <div class="ps-page--shop">
      <div class="ps-container">
        <div class="ps-layout--shop">
          <div class="ps-layout__left mt-5">
            <aside class="widget widget_shop">
              <h4 class="widget-title">Categories</h4>
              <ul class="ps-list--categories">
                <li
                  v-for="category in categories"
                  :key="category.id"
                  class="menu-item-has-children"
                >
                  <a href="#" @click.prevent="selectCategory(category.name)">{{
                    category.name
                  }}</a
                  ><span
                    @click.prevent="selectCategory(category.name)"
                    :class="{
                      'sub-toggle active': category.name == selected_category,
                      'sub-toggle': category.name != selected_category,
                    }"
                    ><i class="fa fa-angle-down"></i
                  ></span>
                  <ul
                    class="sub-menu"
                    :style="[
                      category.name == selected_category
                        ? { display: 'block' }
                        : { display: 'none' },
                    ]"
                  >
                    <li
                      v-for="sub_category in category.sub_catgeories"
                      :key="sub_category.id"
                    >
                      <a href="#">{{ sub_category.name }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </aside>
          </div>
          <div class="ps-layout__right mt-5">
            <div class="ps-block--shop-features">
              <div class="ps-block__header">
                <h3>Search results for {{ query }}</h3>
                <div class="ps-block__navigation">
                  <a class="ps-carousel__prev" href="#recommended1"
                    ><i class="icon-chevron-left"></i></a
                  ><a class="ps-carousel__next" href="#recommended1"
                    ><i class="icon-chevron-right"></i
                  ></a>
                </div>
              </div>
            </div>

            <div class="ps-shopping ps-tab-root">
              <div class="ps-shopping__header">
                <p><strong> {{products.length}}</strong> Products found</p>
                <div class="ps-shopping__actions">
                  <div class="ps-shopping__view">
                    <p>View</p>
                    <ul class="ps-tab-list">
                      <li class="active" id="icon_grid">
                        <a href="#tab-1" @click.prevent="gridView()"
                          ><i class="icon-grid"></i
                        ></a>
                      </li>
                      <li id="icon_list">
                        <a href="#tab-2" @click.prevent="listView()"
                          ><i class="icon-list4"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="ps-tabs">
                <div
                  v-if="loading"
                  class="spinner-border text-center"
                  role="status"
                ></div>
                <div v-if="!loading" class="ps-tab active" id="tab-1">
                  <div class="ps-shopping-product">
                    <div class="row">
                       <div v-for="product in products" :key="product.id" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                        <product-card :product="product"></product-card>
                      </div>
                  
                    </div>
                  </div>
                  <div class="ps-pagination">
                    <ul class="pagination">
                      <li class="active"><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li>
                        <a href="#"
                          >Next Page<i class="icon-chevron-right"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="!loading" class="ps-tab" id="tab-2">
                  <div class="ps-shopping-product">
                    <list-product-card v-for="product in products" :key="product.id" :product="product"></list-product-card>
                  </div>
                  <div class="ps-pagination">
                    <ul class="pagination">
                      <li class="active"><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li>
                        <a href="#"
                          >Next Page<i class="icon-chevron-right"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="shop-filter-lastest" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <!-- <div class="list-group">
                  <a class="list-group-item list-group-item-action" href="#"
                    >Sort by</a
                  ><a class="list-group-item list-group-item-action" href="#"
                    >Sort by average rating</a
                  ><a class="list-group-item list-group-item-action" href="#"
                    >Sort by latest</a
                  ><a class="list-group-item list-group-item-action" href="#"
                    >Sort by price: low to high</a
                  ><a class="list-group-item list-group-item-action" href="#"
                    >Sort by price: high to low</a
                  ><a
                    class="list-group-item list-group-item-action text-center"
                    href="#"
                    data-dismiss="modal"
                    ><strong>Close</strong></a
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from "@/mixins/global.js";
import axios from "axios";
import BreadCrumb from "@/components/Navs/BreadCrumb.vue";
import ProductCard from "@/components/Cards/ProductCard.vue";
import ListProductCard from "@/components/Cards/ListProductCard.vue";

export default {
  components: { BreadCrumb, ProductCard, ListProductCard },
  data() {
    return {
      shop_categories: [],
      query: "",
      selected_category: "All",
      products: [],
      loading: true,
    };
  },
  mixins: [global],
  mounted() {
    this.search();
    this.query = this.$route.params.query;
    if (!localStorage.getItem("bkasjbdfkjasdkfjhaksdfjskd")) {
      localStorage.setItem("bkasjbdfkjasdkfjhaksdfjskd", JSON.stringify([]));
    }
  },
  methods: {
    addToCart(product, prop) {
      let newProp = {
        color: prop.color,
        size: prop.size,
        qty: 1,
      };
      this.add(product, newProp);
    },

    selectCategory(name) {
      this.selected_category = name;
    },

    listView() {
      if (document.getElementById("tab-2").classList.contains("active")) {
        return "";
      } else {
        document.getElementById("tab-1").classList.remove("active");
        document.getElementById("tab-2").classList.add("active");
        document.getElementById("icon_list").classList.add("active");
        document.getElementById("icon_grid").classList.remove("active");
      }
    },

    gridView() {
      if (document.getElementById("tab-1").classList.contains("active")) {
        return "";
      } else {
        document.getElementById("tab-2").classList.remove("active");
        document.getElementById("tab-1").classList.add("active");
        document.getElementById("icon_grid").classList.add("active");
        document.getElementById("icon_list").classList.remove("active");
      }
    },

    search() {
      axios.get("products?query=" + this.$route.params.query).then((response) => {
        this.products = response.data.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
      });
    },
  },
};
</script>
